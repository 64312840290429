/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

import { Box } from './box'
import { FormInput } from './form-input'
import { StepForm } from './step-form'

export const Step1 = ({
  value, handleChange, id, isValid, forceError,
}) => (
  <Box>
    <FormInput
      id={id}
      label="¿Cómo quieres que te llamemos?"
      errorMsg="Debes ingresar un nombre"
      handleChange={handleChange}
      formValue={value}
      placeholderText="Apodo"
      showErr={!isValid}
      forceError={forceError}
      questionNumber={1}
    />
  </Box>
)

Step1.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,

  forceError: PropTypes.bool.isRequired,
}
Step1.defaultProps = {
  value: undefined,
}
