import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { Box } from './box'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const FinalStep = ({ data }) => {
  const [flowResponse, setFlowResponse] = React.useState()
  const baseUrl = 'https://ox589m7qu1.execute-api.us-east-2.amazonaws.com/Prod/'

  useEffect(() => {
    // CREATE USER
    axios.post(`${baseUrl}user/create`, data).then((response) => {
      setFlowResponse(response.data)
      // REGISTER USER (mandar a registrar tarjeta)
      axios
        .post(`${baseUrl}register/${data.documentNumber}`)
        .then((response2) => {
          window.location.href = response2.data.url
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className="flex justify-center items-center h-1/2">
      <h1 className="text-textL text-3xl text-center px-4">
        Registro exitoso!
        <br />
        <br />
        Ahora serás redirigido a nuestro portal de pagos (Flow) para que puedas
        registrar tu medio de pago
      </h1>
    </div>
  )
}

FinalStep.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
}
FinalStep.defaultProps = {
  data: {},
}
