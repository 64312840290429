/* eslint-disable no-nested-ternary */
import { CheckIcon } from '@heroicons/react/solid'
import React from 'react'
import PropTypes from 'prop-types'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Step({ steps }) {
  return (
    <nav aria-label="Progress" className="flex items-center w-full">
      <ol className="flex items-center justify-center w-full">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-6 sm:pr-20' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-redL" />
                </div>
                <span
                  href="#"
                  className="relative flex items-center justify-center w-5 h-5 rounded-full md:w-8 md:h-8 bg-redL hover:bg-pinkL"
                >
                  <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            ) : step.status === 'current' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span
                  href="#"
                  className="relative flex items-center justify-center w-5 h-5 bg-white border-2 rounded-full md:w-8 md:h-8 border-redL"
                  aria-current="step"
                >
                  <span className="h-2.5 w-2.5 bg-redL rounded-full" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span
                  href="#"
                  className="relative flex items-center justify-center w-5 h-5 bg-white border-2 border-gray-300 rounded-full md:w-8 md:h-8 group hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

Step.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      status: PropTypes.oneOf(['complete', 'current', 'upcoming']).isRequired,
    }),
  ).isRequired,
}
