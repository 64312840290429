/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

import logoWhite from '../assets/imgs/logo-white.png'

export const Header = ({ alias }) => (
  <div className="flex flex-col items-center justify-center w-full h-32 md:h-20 bg-pinkL">
    <img src={logoWhite} alt="logo lyfe" className="h-12 ml-8 md:absolute md:left-0" />
    <div>
      <h1 className="w-full text-3xl text-center text-white capitalize font-brandonBlack">
        Bienvenid@
        {' '}
        {alias}
      </h1>
      <p className="w-full text-lg text-center text-white">Estás a pocos pasos de contratar tu seguro Lyfe</p>
    </div>
  </div>
)

Header.propTypes = {
  alias: PropTypes.string,
}
Header.defaultProps = {
  alias: '',
}
