/**
 * Format a chilean rut of maximum 999.999.999-9
 */
export default function formatDocumentNumber(documentNumber) {
  // Opted for this approach to have a "more legible" way of doing it.
  const parts = [
    documentNumber.slice(-10, -7),
    documentNumber.length > 7 ? '.' : '',
    documentNumber.slice(-7, -4),
    documentNumber.length > 4 ? '.' : '',
    documentNumber.slice(-4, -1),
    documentNumber.length > 1 ? '-' : '',
    documentNumber.slice(-1).toUpperCase(),
  ]
  return parts.join('')
}
