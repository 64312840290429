import cleanDocumentNumber from './clean-document-number'

export default function separateDocumentNumber(
  documentNumber: string,
) {
  const cleanedDocumentNumber = cleanDocumentNumber(documentNumber)

  const digits = cleanedDocumentNumber.slice(0, -1)
  const verificationDigit = cleanedDocumentNumber.slice(-1)

  return { digits, verificationDigit }
}
