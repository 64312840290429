/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ArrowRightIcon } from '@heroicons/react/solid'

export const FormInput = ({
  id,
  label,
  errorMsg,
  handleChange,
  formValue,
  placeholderText,
  showErr,
  forceError,
  type,
  questionNumber,
  maxLength,
}) => {
  const [touched, setTouched] = useState(false)
  const showError = (touched || forceError) && showErr
  return (
    <>
      <label htmlFor={id}>
        <span className="text-textL">
          <span className="flex items-center text-xl text-redL">
            {questionNumber}
            {questionNumber ? <ArrowRightIcon className="w-4 h-4 mr-2 text-redL" aria-hidden="true" /> : null}
            <span className="text-textL">{label}</span>
          </span>
        </span>
      </label>
      <input
        onBlur={() => setTouched(true)}
        placeholder={placeholderText}
        type={type ?? 'text'}
        id={id}
        value={formValue}
        onChange={handleChange}
        className="w-full my-3 border-0 rounded-sm text-redL bg-backgroundL h-14"
        maxLength={maxLength}
      />
      <p className="text-lg text-redL">{showError ? errorMsg : null}</p>
    </>
  )
}

FormInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  handleChange: PropTypes.func,
  formValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholderText: PropTypes.string,
  showErr: PropTypes.bool,
  forceError: PropTypes.bool,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  questionNumber: PropTypes.any,
}
FormInput.defaultProps = {
  id: null,
  label: null,
  errorMsg: null,
  type: undefined,
  handleChange: null,
  formValue: null,
  placeholderText: null,
  showErr: false,
  forceError: false,
  maxLength: undefined,
  questionNumber: null,
}
