// eslint-disable-next-line import/no-unresolved
import './app.css'
import React from 'react'
import {
  useLocation, BrowserRouter as Router, Route, Routes,
} from 'react-router-dom'

import Home from 'views/home'
import Register from 'views/register'
import ErrorFlow from 'components/error-flow'

const mapSearchParamsToObject = (searchParams) => {
  const params = new URLSearchParams(searchParams)
  const obj = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of params) {
    obj[key] = value
  }
  return obj
}

const App = () => {
  const { search } = useLocation()
  const documentNumber = mapSearchParamsToObject(search)?.documentNumber
  const error = mapSearchParamsToObject(search)?.error

  if (documentNumber) { return <Register /> }
  if (error) { return <ErrorFlow /> }
  return <Home />
}

export default function router() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<App />} />
      </Routes>
    </Router>
  )
}
