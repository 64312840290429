import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cleanDocumentNumber from 'utils/clean-document-number'
import separateDocumentNumber from 'utils/separate-document-number'
import formatDocumentNumber from 'utils/format-document-number'
import isDocumentNumberValid from 'utils/is-document-number-valid'
import { StepForm } from './step-form'

// phone number
import { Box } from './box'
import { FormInput } from './form-input'

export const Step4 = ({
  value, handleChange, id, forceError, isValid,
}) => {
  const [localValue, setLocalValue] = useState(value)
  const handleBlur = () => {
    setLocalValue((prev) => formatDocumentNumber(cleanDocumentNumber(prev)))
  }
  const localHandleChange = (event) => {
    setLocalValue(formatDocumentNumber(cleanDocumentNumber(event.target.value)))
    const { digits, verificationDigit } = separateDocumentNumber(
      cleanDocumentNumber(event.target.value),
    )
    handleChange({
      ...event,
      target: {
        ...event.target,
        value: `${digits}-${verificationDigit}`,
      },
    })
  }

  useEffect(() => {
    setLocalValue(formatDocumentNumber(cleanDocumentNumber(value)))
  }, [value])

  return (
    <Box>
      <FormInput
        id="rut"
        label="Ingresa tu rut"
        errorMsg="Debes ingresar un rut válido"
        handleChange={localHandleChange}
        formValue={localValue}
        placeholderText="11.111.111-1"
        onBlur={handleBlur}
        showErr={!isValid}
        forceError={forceError}
        questionNumber={4}
      />
    </Box>
  )
}

Step4.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  forceError: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
}
Step4.defaultProps = {
  value: undefined,
}
