/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from './box'

export const Step9 = ({ data }) => {
  let planPrice = 0
  let cargas = 0
  let planName = ''
  if (data.planType === 'full') {
    planName = 'Plan Full'
    switch (data.numberOfLoads) {
      case 'carga0':
        planPrice = '1,39 UF'
        cargas = 'Sin cargas'
        break
      case 'carga1':
        planPrice = '2,42 UF'
        cargas = '1 carga'
        break
      case 'carga2':
        planPrice = '3,10 UF'
        cargas = '2 cargas'
        break
      case 'carga3':
        planPrice = '3,99 UF'
        cargas = '3+ cargas'
        break
      default:
        break
    }
  } else if (data.planType === 'básico') {
    planName = 'Plan Básico'
    switch (data.numberOfLoads) {
      case 'carga0':
        planPrice = '1,10 UF'
        cargas = 'Sin cargas'
        break
      case 'carga1':
        planPrice = '1,87 UF'
        cargas = '1 carga'
        break
      case 'carga2':
        planPrice = '2,39 UF'
        cargas = '2 cargas'
        break
      case 'carga3':
        planPrice = '3,06 UF'
        cargas = '3+ cargas'
        break
      default:
        break
    }
  }

  return (
    <Box>
      <h1 className="mx-auto text-xl text-center">
        {`Felicitaciones ${data.alias}`}
        <br />
        Estás a un sólo paso de contratar tu seguro Lyfe
      </h1>
      <h1 className="mx-auto text-xl text-center mt-8 font-bold">
        Resumen de contratación
      </h1>
      <div className="border-pinkL border rounded flex mx-auto mt-2">
        <div className="flex flex-col items-center text-white text-xl bg-pinkL p-5">
          <span>{planName}</span>
          <span>{cargas}</span>
        </div>
        <div className="flex flex-col items-center text-pinkL text-xl p-5">
          <span className="font-brandonBlack">{planPrice}</span>
          <span>(valor mensual)</span>
        </div>
      </div>
      <h1 className="mx-auto mt-10 text-2xl text-center font-brandonBlack">
        Si estás seguro de tu elección y quieres finalizar la contratación,
        <br />
        continúa al último paso donde podrás registrar tu medio de pago.
      </h1>
      <h1 className="mx-auto mt-10 text-xl text-center">
        Si aún tienes dudas, te invitamos a visitar nuestro centro de ayuda
        {' '}
        <a href="https://liyfe.zendesk.com/hc/es-419" className="font-bold text-blue-600 hover:text-redL">AQUÍ</a>
        <br />
        O escribirnos al correo info@lyfe.cl
      </h1>
    </Box>
  )
}

Step9.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
}
Step9.defaultProps = {
  data: {},
}
