import getCheckDigit from './get-check-digit'

/**
 * Validates a chilean rut. Accepts masked or unmasked values.
 * Longest value has 9 digits and 1 verifying digit
 * @param {string} value
 * @return {boolean} is the document number valid or not
 */
export default function isDocumentNumberValid(rawValue = '') {
  const value = rawValue.toUpperCase().replace(/[^K\d]+/gi, '')

  if (value.length < 2 || value.length > 10) {
    return false
  }

  const body = parseInt(value.slice(0, -1), 10)
  const expectedVD = getCheckDigit(body)

  return expectedVD === value.slice(-1)
}
