const { Step1 } = require('./step-1')
const { Step2 } = require('./step-2')
const { Step3 } = require('./step-3')
const { Step4 } = require('./step-4')
const { Step5 } = require('./step-5')
const { Step6 } = require('./step-6')
const { Step7 } = require('./step-7')
const { Step8 } = require('./step-8')
const { Step9 } = require('./step-9')
const { FinalStep } = require('./final-step')

export {
  Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9, FinalStep,
}
