import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StepForm } from './step-form'

import { Box } from './box'
import { FormInput } from './form-input'
// phone

export const Step5 = ({
  value, handleChange, id, forceError, isValid,
}) => {
  const [localValue, setLocalValue] = useState(value)

  const localHandleChange = (event) => {
    setLocalValue(event.target.value.replace(/[^\d+]/g, ''))
    handleChange({
      ...event,
      target: {
        ...event.target,
        value: event.target.value.replace(/[^\d+]/g, ''),
      },
    })
  }

  useEffect(() => {
    setLocalValue(value)
  }, [value])
  return (
    <Box>
      <FormInput
        id="numero-telefono"
        label="Ingresa tu número de teléfono"
        errorMsg="Debes ingresar un número de teléfono válido"
        handleChange={localHandleChange}
        formValue={localValue}
        placeholderText="ej: 912345678"
        showErr={!isValid}
        forceError={forceError}
        questionNumber={5}
        maxLength={12}
      />
    </Box>
  )
}

Step5.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  forceError: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,

}
Step5.defaultProps = {
  value: undefined,
}
