import { Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import React from 'react'

export const TransitionLeftToRight = ({ show, children }) => (
  <Transition
    className="w-full"
    show={show}
    enter="transition ease duration-200 transform"
    enterFrom="opacity-0 -translate-x-full"
    enterTo="opacity-100 translate-x-0"
    leave="transition ease duration-0 transform"
    leaveFrom="opacity-100 translate-x-0"
    leaveTo="opacity-0 -translate-x-full"
  >
    {children}
  </Transition>
)
TransitionLeftToRight.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
}
