import React from 'react'

const ErrorFlow = () => (
  <div className="flex items-center justify-center h-screen text-2xl">
    <h1>
      Error en el registro, el usuario con el RUT asignado ya existe.
    </h1>
  </div>
)

export default ErrorFlow
