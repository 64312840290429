import React from 'react'
import PropTypes from 'prop-types'
import { StepForm } from './step-form'

// birth date
import { Box } from './box'
import { FormInput } from './form-input'

export const Step2 = ({
  name, lastName, handleChangeName, handleChangeLastName, id, forceError,
}) => (
  <Box>
    <FormInput
      id="nombre"
      label="Ingresa tu nombre"
      errorMsg="Debes ingresar tu Nombre"
      handleChange={handleChangeName}
      formValue={name}
      placeholderText="Nombre"
      showErr={name === ''}
      forceError={forceError}
      questionNumber={2}
    />
    <FormInput
      id="apellido"
      label="Ingresa tu apellido"
      errorMsg="Debes ingresar tu apellido"
      handleChange={handleChangeLastName}
      formValue={lastName}
      placeholderText="Appellido"
      showErr={lastName === ''}
      forceError={forceError}
    />
  </Box>
)

Step2.propTypes = {
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeLastName: PropTypes.func.isRequired,
  forceError: PropTypes.bool.isRequired,
}
