import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

// number of loads
import { RadioGroup } from '@headlessui/react'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { StepForm } from './step-form'
import { Box } from './box'
import { FormInput } from './form-input'
import carga0 from '../assets/imgs/carga0.png'
import carga1 from '../assets/imgs/carga1.png'
import carga2 from '../assets/imgs/carga2.png'
import carga3 from '../assets/imgs/carga3.png'

const plans = [
  {
    name: 'Tú solo',
    value: 'carga0',
  },
  {
    name: 'Tú y 1 carga',
    value: 'carga1',
  },
  {
    name: 'Tú y 2 cargas',
    value: 'carga2',
  },
  {
    name: 'Tú y 3 o más cargas',
    value: 'carga3',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Step8 = ({ value, handleChange, id }) => {
  const [selected, setSelected] = useState()
  const inputRef = useRef()

  const changeFocus = () => {
    inputRef?.current?.focus()
  }

  const cargaImg = (planName) => {
    switch (planName) {
      case 'carga0':
        return (
          <img src={carga0} alt="logo lyfe" className="h-10 md:h-12 m-3" />
        )
      case 'carga1':
        return (
          <img src={carga1} alt="logo lyfe" className="h-10 md:h-12 m-3" />
        )
      case 'carga2':
        return (
          <img src={carga2} alt="logo lyfe" className="h-10 md:h-12 m-3" />
        )
      case 'carga3':
        return (
          <img src={carga3} alt="logo lyfe" className="h-10 md:h-12 m-3" />
        )
      default:
        return 'asd'
    }
  }

  useEffect(() => {
    setInterval(() => {
      changeFocus()
    }, 100)
  }, [])

  return (
    <Box className="w-screen">
      <span className="flex items-center text-xl text-redL mr-auto">
        8
        <ArrowRightIcon className="w-4 h-4 mr-2 text-redL" aria-hidden="true" />
        <span className="text-textL">¿Cuántas cargas quieres ingresar?</span>
      </span>
      <input className="w-0 h-0" ref={inputRef} />

      <RadioGroup
        value={selected}
        onChange={(e) => {
          setSelected(e)
          handleChange({ target: { value: e.value } })
          changeFocus()
        }}
        className="mx-auto"
      >
        <RadioGroup.Label className="sr-only">Tipo de Plan</RadioGroup.Label>
        <div className="flex flex-row flex-wrap justify-center">
          {plans.map((plan) => (
            <RadioGroup.Option
              key={plan.name}
              value={plan}
              className={({ active }) => classNames(
                active ? 'ring-1 ring-offset-2 ring-indigo-500' : '',
                'flex justify-center items-center relative rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 focus:outline-none m-3 h-32 w-32 md:m-6 md:h-40 md:w-40',
              )}
            >
              {({ checked }) => (
                <>
                  <div className="flex items-center">
                    <div className="text-base">
                      <RadioGroup.Label
                        as="p"
                        className="font-medium text-gray-900 text-center"
                      >
                        {plan.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="div"
                        className="text-gray-500 flex justify-center"
                      >
                        {cargaImg(plan.value)}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg border-2 pointer-events-none',
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </Box>
  )
}

Step8.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}
Step8.defaultProps = {
  value: undefined,
}
