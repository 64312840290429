/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import es from 'date-fns/locale/es'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { coolGray } from 'tailwindcss/colors'
import { StepForm } from './step-form'
import { Box } from './box'

export const Step3 = ({
  value,
  handleChange,
  id,
  forceError,
  isValid,
  handleSubmit,
}) => {
  const [date, setDate] = useState(value)
  const [touched, setTouched] = useState(false)

  return (
    <Box>
      <label htmlFor={id}>
        <span className="flex items-center text-xl text-redL">
          3
          <ArrowRightIcon
            className="w-4 h-4 mr-2 text-redL"
            aria-hidden="true"
          />
          <span className="text-textL">Ingresa tu fecha de nacimiento</span>
        </span>
      </label>
      <div>
        <div className="relative">
          <DatePicker
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit({
                  preventDefault: () => null,
                })
              }
            }}
            onBlur={() => {
              setTouched(true)
            }}
            selected={
              dayjs(date ?? value ?? new Date()).isValid()
                ? dayjs(date ?? value ?? new Date()).toDate()
                : null
            }
            onChange={(d) => {
              setDate(d)
              handleChange({ target: { value: d } })
            }}
            selectsStart
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            // maxDate={dayjs().subtract(18, 'years').toDate()}
            locale={es}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/aaaa"
          />
        </div>
      </div>
      <p className="text-lg text-redL">
        {(touched || forceError) && !isValid
          ? date <= dayjs().subtract(18, 'years').toDate()
            ? (date >= dayjs().subtract(70, 'years').toDate()
              ? 'Ingresa una fecha de nacimiento'
              : 'Debes ser menor de 70 años')
            : 'Debes ser mayor de 18 años'
          : null}
      </p>
    </Box>
  )
}

Step3.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  forceError: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}
Step3.defaultProps = {
  value: undefined,
}
