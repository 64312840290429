import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logoWhite from '../../assets/imgs/logo-white.png'

const mapSearchParamsToObject = (searchParams) => {
  const params = new URLSearchParams(searchParams)
  const obj = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of params) {
    obj[key] = value
  }
  return obj
}
const baseUrl = 'https://ox589m7qu1.execute-api.us-east-2.amazonaws.com/Prod/'
const Register = () => {
  // get documentNumber from query parameters
  const [state, setState] = useState('LOADING')
  const { search } = useLocation()
  const documentNumber = mapSearchParamsToObject(search)?.documentNumber

  useEffect(() => {
    if (documentNumber) {
      axios.get(`${baseUrl}register/${documentNumber}/status`).then((response) => {
        if (response.data.status === '1') {
          setState('REGISTERED')
          axios.post(`${baseUrl}subscription/${documentNumber}`)
        } else {
          setState('NOT_REGISTERED')
        }
      })
    }
  }, [documentNumber])

  // if state is 'REGISTERED' show success
  if (state === 'REGISTERED') {
    return (
      <div className="flex items-center justify-start flex-col h-screen">
        <div className="flex flex-col items-center justify-center w-full h-32 md:h-20 bg-pinkL">
          <img src={logoWhite} alt="logo lyfe" className="h-12 ml-8 md:absolute md:left-0" />
          <div>
            <h1 className="w-full text-3xl text-center text-white capitalize font-brandonBlack">
              Registro Exitoso!
            </h1>
          </div>
        </div>
        <div className="flex items-center flex-col justify-center my-auto">
          <h1 className="text-xl text-center md:text-2xl w-2/3 my-5">
            Hemos recibido la inscripción de tu medio de pago en forma exitosa.
          </h1>
          <h1 className="text-xl text-center md:text-2xl w-2/3">
            Dentro de las próximas horas hábiles un ejecutivo validará la información
            y te enviaremos un correo de SURA con el enlace para que puedas
            completar el
            {' '}
            <span className="font-brandonBlack">Formulario de Contratación</span>
            {' '}
            donde podrás, entre otras cosas, inscribir tus cargas.

          </h1>
        </div>
      </div>
    )
  }

  // if state is 'NOT_REGISTERED' show error
  if (state === 'NOT_REGISTERED') {
    return (
      <div className="m-auto mt-2">
        <h1 className="text-center text-xl">Error del sistema: no ha podido completarse la suscripción</h1>
      </div>
    )
  }
  // if state is 'LOADING' show loading
  return (
    <div className="m-auto mt-2">
      <h1 className="text-center text-xl">Cargando...</h1>
    </div>
  )
}

export default Register
