/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/solid'

import Step from './step'

export const StepForm = ({
  children, handleSubmit, handleBack, steps,
}) => {
  const currentStep = steps.findIndex((step) => step.status === 'current') + 1

  return (
    <form onSubmit={handleSubmit} className="top-0 left-0 flex flex-col items-center content-center justify-between flex-grow w-screen overflow-x-hidden overflow-y-scroll">
      <div className="w-full">
        {children}
      </div>
      <div className="flex flex-col items-center justify-center w-full fixed bottom-0">
        <div className="w-full my-6">
          <Step steps={steps} />
        </div>
        <div className="w-full bg-pinkL">
          <div className="flex flex-row items-center justify-center py-4">
            <button className="group flex items-center justify-center w-12 h-12 p-3 rounded-full bg-white hover:bg-redL" type="button" onClick={handleBack}>
              <ArrowLeftIcon className="w-5 h-5 text-redL group-hover:text-white" aria-hidden="true" />
            </button>
            {/* <h2 className="px-20 text-white">
              {currentStep}
              {' '}
              de
              {' '}
              {steps.length}
            </h2> */}
            <div className="px-20" />
            <button className="group flex items-center justify-center w-12 h-12 p-3 rounded-full bg-white hover:bg-redL" type="submit">
              <ArrowRightIcon className="w-5 h-5 text-redL group-hover:text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
StepForm.propTypes = {
  children: PropTypes.element,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.any.isRequired,
}
StepForm.defaultProps = {
  children: null,
}
export default StepForm
