/**
 * Generate verifying or check digit of the given rut.
 *
 * https://es.wikipedia.org/wiki/Anexo:Implementaciones_para_algoritmo_de_rut#Javascript
 *
 * @param  {number} rut The rut without verifying digit
 * @return {string} The check digit of the rut.
 */
export default function getCheckDigit(rut) {
  let mult = 0
  let acum = 1

  for (let value = rut; value; value = Math.floor(value / 10)) {
    acum = (acum + (value % 10) * (9 - (mult % 6))) % 11
    mult += 1
  }

  return acum ? (acum - 1).toString() : 'K'
}
