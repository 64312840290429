/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const Box = ({ children }) => (
  <div className="h-screen flex flex-col items-start mx-auto my-10 bg-white rounded-lg md:p-5 text-textL md:w-7/12">
    {children}
  </div>
)

Box.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}
Box.defaultProps = {
  children: null,
}
