/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import Step from 'components/step'
import {
  Step1,
  Step7,
  Step6,
  Step5,
  Step4,
  Step3,
  Step2,
  Step8,
  Step9,
  FinalStep,
} from 'components'
import React, { useEffect, useState } from 'react'
import { StepForm } from 'components/step-form'
import { Header } from 'components/header'
import { TransitionRightToLeft } from 'components/transition-right-to-left'
import { TransitionLeftToRight } from 'components/transition-left-to-right'
import dayjs from 'dayjs'
import cleanDocumentNumber from 'utils/clean-document-number'
import isDocumentNumberValid from 'utils/is-document-number-valid'
import { isPhoneNumberValid } from 'utils/is-phone-number-valid'
import { validate as isEmailValid } from 'email-validator'

function Home() {
  const [steps, setSteps] = useState([
    {
      name: 'Step 1',
      index: 1,
      status: 'current',
    },
    {
      name: 'Step 2',
      index: 2,
      status: 'upcoming',
    },
    {
      name: 'Step 3',
      index: 3,
      status: 'upcoming',
    },
    {
      name: 'Step 4',
      index: 4,
      status: 'upcoming',
    },
    {
      name: 'Step 5',
      index: 5,
      status: 'upcoming',
    },
    {
      name: 'Step 6',
      index: 6,
      status: 'upcoming',
    },
    {
      name: 'Step 7',
      index: 7,
      status: 'upcoming',
    },
    {
      name: 'Step 8',
      index: 8,
      status: 'upcoming',
    },
    {
      name: 'Step 9',
      index: 9,
      status: 'upcoming',
    },
  ])
  const [inputs, setInputs] = useState({
    numberOfLoads: '',
    planType: '',
    email: '',
    phoneNumber: '',
    documentNumber: '',
    birthDate: '',
    lastName: '',
    alias: '',
    name: '',
    planId: 'TEST',
  })
  const [normalFlow, setNormalFlow] = useState(true)
  const [forceError, setForceError] = useState(false)

  const current = steps.find((step) => step.status === 'current')
  const index = current?.index

  let isValid = false

  if (index === 1) isValid = inputs.alias !== ''
  if (index === 2) isValid = inputs.name !== '' && inputs.lastName !== ''
  if (index === 3) {
    isValid = inputs.birthDate !== ''
      && inputs.birthDate !== 'Invalid Date'
      && inputs.birthDate !== undefined
      && inputs.birthDate !== null
      && inputs.birthDate <= dayjs().subtract(18, 'years').toDate()
      && inputs.birthDate >= dayjs().subtract(70, 'years').toDate()
  }
  if (index === 4) { isValid = isDocumentNumberValid(cleanDocumentNumber(inputs.documentNumber)) }
  if (index === 5) isValid = isPhoneNumberValid(inputs.phoneNumber)
  if (index === 6) isValid = isEmailValid(inputs.email)
  if (index === 7) isValid = inputs.planType !== ''
  if (index === 8) isValid = inputs.numberOfLoads !== ''
  if (index === 9) isValid = true

  const handleClick = () => {
    setSteps((prevSteps) => {
      const copySteps = [...prevSteps]
      const current = steps.findIndex((step) => step.status === 'current')
      copySteps[current] = { ...copySteps[current], status: 'complete' }
      if (current + 1 !== prevSteps.length) {
        copySteps[current + 1] = {
          ...copySteps[current + 1],
          status: 'current',
        }
      }
      setNormalFlow(true)
      return copySteps
    })
  }

  // Definimos el planID que enviaremos a FLOW, segun la data completada por el usuario
  useEffect(() => {
    if (inputs.planType === 'full') {
      switch (inputs.numberOfLoads) {
        case 'carga0':
          inputs.planId = 'FULL-0'
          break
        case 'carga1':
          inputs.planId = 'FULL-1'
          break
        case 'carga2':
          inputs.planId = 'FULL-2'
          break
        case 'carga3':
          inputs.planId = 'FULL-3'
          break
        default:
          break
      }
    } else if (inputs.planType === 'básico') {
      switch (inputs.numberOfLoads) {
        case 'carga0':
          inputs.planId = 'BAS-0'
          break
        case 'carga1':
          inputs.planId = 'BAS-1'
          break
        case 'carga2':
          inputs.planId = 'BAS-2'
          break
        case 'carga3':
          inputs.planId = 'BAS-3'
          break
        default:
          break
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.numberOfLoads])

  useEffect(() => {
    inputs.alias = inputs.alias.charAt(0).toUpperCase() + inputs.alias.substring(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.alias])

  useEffect(() => {
    inputs.name = inputs.name.charAt(0).toUpperCase() + inputs.name.substring(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.name])

  useEffect(() => {
    inputs.lastName = inputs.lastName.charAt(0).toUpperCase() + inputs.lastName.substring(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.lastName])

  const handleBack = () => {
    setSteps((prevSteps) => {
      const copySteps = [...prevSteps]
      const current = steps.findIndex((step) => step.status === 'current')

      if (current === 0) return prevSteps
      copySteps[current] = { ...copySteps[current], status: 'upcoming' }
      copySteps[current - 1] = { ...copySteps[current - 1], status: 'current' }
      setNormalFlow(false)
      return copySteps
    })
  }

  const handleChange = (inputName, isValid = true) => (event) => {
    setInputs((prevState) => ({
      ...prevState,
      [inputName]: event.target.value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!isValid) {
      setForceError(true)
      return
    }
    setForceError(false)
    handleClick()
  }
  const StepsComponents = [
    <Step1
      isValid={isValid}
      handleBack={handleBack}
      handleChange={handleChange('alias')}
      id="alias"
      value={inputs.alias}
      forceError={forceError}
    />,
    <Step2
      isValid={isValid}
      name={inputs.name}
      lastName={inputs.lastName}
      handleChangeLastName={handleChange('lastName')}
      handleChangeName={handleChange('name')}
      id="2"
      forceError={forceError}
    />,
    <Step3
      isValid={isValid}
      value={dayjs(inputs.birthDate, 'DD/MM/YYYY').toDate()}
      handleChange={handleChange('birthDate')}
      id="3"
      forceError={forceError}
      handleSubmit={handleSubmit}
    />,
    <Step4
      isValid={isValid}
      value={inputs.documentNumber}
      handleChange={handleChange('documentNumber')}
      id="4"
      forceError={forceError}
    />,
    <Step5
      isValid={isValid}
      value={inputs.phoneNumber}
      handleChange={handleChange('phoneNumber')}
      id="5"
      forceError={forceError}
    />,
    <Step6
      isValid={isValid}
      value={inputs.email}
      handleChange={handleChange('email')}
      id="6"
      forceError={forceError}
    />,
    <Step7
      isValid={isValid}
      handleChange={handleChange('planType')}
      id="7"
      forceError={forceError}
    />,
    <Step8
      isValid={isValid}
      handleChange={handleChange('numberOfLoads')}
      id="8"
      forceError={forceError}
    />,
    <Step9
      data={inputs}
      isValid={isValid}
    />,
  ]

  return (
    <div className="w-full h-1 min-h-screen overflow-hidden bg-white text-textL">
      <Header alias={index !== 1 ? inputs.alias : ''} />
      {steps[8].status === 'complete' ? (
        <FinalStep data={inputs} />
      ) : (
        <StepForm
          handleSubmit={handleSubmit}
          handleBack={handleBack}
          steps={steps}
        >
          <div className="flex flex-col items-center w-full">
            {StepsComponents.map((Component, indexComponent) => (
              <React.Fragment key={`3${indexComponent}`}>
                <TransitionLeftToRight
                  key={`1${indexComponent}`}
                  show={index === indexComponent + 1 && !normalFlow}
                >
                  {Component}
                </TransitionLeftToRight>
                <TransitionRightToLeft
                  key={`2${indexComponent}`}
                  show={index === indexComponent + 1 && normalFlow}
                >
                  {Component}
                </TransitionRightToLeft>
              </React.Fragment>
            ))}
          </div>
        </StepForm>
      )}
    </div>
  )
}

export default Home
